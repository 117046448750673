import React from 'react';
import {connect }from 'react-redux';
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import Button from "../components/CustomButtons/Button";
import {AddAlert, AddBox, Close, Edit} from '@material-ui/icons'
import PadModal from '../components/Modals/padModal';
import Modal from "../components/Modals/Modal";
import {addJudgePad,updateJudgePad, getAdminData} from "../Store/Actions/AdminActions";
import {Tooltip} from "@material-ui/core";
import Table from "../components/Table/Table";
import CardBody from "../components/Card/CardBody";
import Loading from "../components/Loading";
import Snackbar from "../components/Snackbar/Snackbar";


class AdminAdjPads extends React.Component{

    state={
        loading:false,
        padModal:false,
        heading:'',
        key:null,
        judgeData:null,
        deleteModal:false,
        error:'',
        notif:false,
        notifMsg:'',
        editMode:false
    };

    onSave=(formValues)=>{
        this.setState({loading:true});
        if(this.state.key){
            this.props.updateJudgePad(formValues,this.state.key,res=>{
                res.status !==1?
                    this.setState({loading:false,error:res.msg}):
                    this.props.getAdminData(()=>
                        this.setState({
                            loading:false,
                            padModal:false,
                            heading:'',
                            key:'',
                            error:'',
                            notif:true,
                            notifMsg:'Details loaded successfully!'
                        })
                    )

            })
        }else{
            this.props.addJudgePad(formValues,res=>{
                res.status !==1?
                    this.setState({loading:false,error:res.msg}):
                    this.props.getAdminData(()=>
                        this.setState({
                            loading:false,
                            padModal:false,
                            heading:'',
                            key:'',
                            error:'',
                            notif:true,
                            notifMsg:'Details loaded successfully!'
                        })
                    )
            })
        }

    };

    renderJudges=()=>{
        const {judgesData}=this.props;
        if(judgesData !== null && judgesData !== undefined){
            const tableData=Object.keys(judgesData).reduce((a,v)=>{

                const {email,password,deviceNo,adminPass,deviceId}=judgesData[v];

                a.push([
                    email,
                    password,
                    deviceNo,
                    adminPass,
                    deviceId,
                    (<div>
                        <Tooltip title='Delete Judge'>
                            <Button color='danger' size='sm' justIcon round>
                                <Close/>
                            </Button>
                        </Tooltip>
                        <Tooltip title='Edit Judge'>
                            <Button color='warning' size='sm' justIcon round onClick={()=>this.setState({
                                padModal:true,
                                key:v,
                                judgeData:judgesData[v],
                                heading:'Edit Judge',
                                editMode:true
                            })} >
                                <Edit/>
                            </Button>
                        </Tooltip>

                    </div>)
                ]);
                return a;
            },[]);

            const tableHead=['Email','Password','Device No','AdminPass','Device Id','Actions'];
            return <Table
                    tableData={tableData}
                    tableHead={tableHead}
                    tableHeaderColor={'info'}
            />

        }else{
            return <div>No Judges added Yet</div>
        }
    };

    render(){
        const {padModal, heading,judgeData, deleteModal,error,loading,editMode, notif,notifMsg}=this.state;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <Modal
                    isVisible={deleteModal}
                    onClose={()=>this.setState({deleteModal: false,key:false})}
                    saveButtonColor='danger'
                    saveButtonText='Delete'
                />
                <PadModal
                    editMode={editMode}
                    errMessage={error}
                    data={judgeData}
                    isVisible={padModal}
                    heading={heading}
                    onClose={()=>this.setState({padModal:false,key:null,judgeData:null, editMode: false})}
                    onSave={this.onSave}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={notifMsg}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Card>
                    <CardHeader color={"primary"}>
                        <h4>Judges Pads</h4>
                        <Button color={'warning'} round size={'sm'} onClick={()=>this.setState({padModal:true,heading:'Add Judge Pad'})}>
                            <AddBox/>
                            Add Judging Pad
                        </Button>
                    </CardHeader>
                    <CardBody>
                        {this.renderJudges()}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
const mapStateToProps=state=>{

    if(state.adminData.adminData){
        return {judgesData:state.adminData.adminData.judgesPads}
    }else{
        return {judgesData: null}
    }


};


export default connect(mapStateToProps,{addJudgePad,updateJudgePad, getAdminData})(AdminAdjPads);