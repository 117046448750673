import React from "react";
import {connect}from 'react-redux';
import {reduxForm,Field} from "redux-form";

import Loading from "../../components/Loading";
import {validateFormEntries} from "../../Functions/Validation";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Danger from "../../components/Typography/Danger";
import RenderInputs from "../../Functions/RenderInputs";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import {adminLogin, checkLogin} from "../../Store/Actions/AuthActions";
import history from "../../history";
import {getAdminData} from "../../Store/Actions/AdminActions";


const bgImage = require('../../assets/img/backgrounds/contempBg.jpg');
const ValueArr=[
    {
        name:'email',
        type:'email',
        placeholder:'Your email...',
        label:'Email'
    },
    {
        name:'password',
        type:'password',
        placeholder: '********',
        label:'Password'
    }
];


class Auth extends React.Component{

    componentDidMount() {
        this.props.checkLogin(res=>{
            if(res.status===1){
                this.setState({loading:true});
                this.props.getAdminData(res=>{
                    history.push('/home/events');
                });
            }
        })
    }

    state={
        loginError:'',
        loading:false
    }

    handleOnSubmit=(formValues)=>{
        this.setState({loading:true});
        this.props.adminLogin(formValues,res=>{
            if(res.status===1){
                this.props.getAdminData(res=>{
                    this.setState({loading:false,loginError:''});
                    history.push('/home/events');
                });
            }else{
                this.setState({loading:false,loginError:res.msg});
            }
        })
    }

    render(){
        const {loading, loginError}=this.state;
        return(
            <div
                style={{
                    background:`url(${bgImage})`,
                    //backgroundAttachment:'fixed',
                    backgroundPosition:'center top',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    height:window.innerHeight,
                    width:window.innerWidth,
                    textAlign:'center'
                }}
            >
                <Loading show={loading}/>
                <form onSubmit={this.props.handleSubmit(this.handleOnSubmit)}>
                    <GridContainer justify={'center'} align={'center'} style={{paddingLeft:10,paddingRight:10}}>
                        <GridItem sm={12} md={4} container justify={'center'} align={'center'} style={{marginTop:100}}>
                            <Card>
                                <CardHeader color={'info'}>
                                    <h3>Admin Authorization</h3>
                                    <p>Please log in with your details below</p>
                                </CardHeader>
                                <CardBody>
                                    {loginError?<Danger>{loginError}</Danger>:null}
                                    {ValueArr.map(({name,type,placeholder,label},index)=>{
                                        return (
                                            <Field
                                                key={name+index}
                                                width={true}
                                                name={name}
                                                type={type}
                                                placeholder={placeholder}
                                                label={label}
                                                component={RenderInputs}
                                            />
                                        )
                                    })}
                                </CardBody>
                                <CardFooter>
                                    <Button type={'submit'} size={'sm'} color={'info'} block round>Login</Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        )
    }
}



const validate=(formValues)=>{
    return validateFormEntries(ValueArr,formValues);
}
const Form=reduxForm({
    form:'AdminAuth',
    validate
})(Auth);

const mapStateToProps=(state)=>{
  return {}
};

export default connect(mapStateToProps,{adminLogin, getAdminData, checkLogin})(Form);