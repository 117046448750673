import {LOAD_ADMIN_DATA} from "../types";

const INITIAL_STATE={
    users:null,
    events:null,
    adminData:null,

};

export default (state=INITIAL_STATE,action)=>{
    switch (action.type) {
        case LOAD_ADMIN_DATA:
            return {users:action.payload.users, events:action.payload.events, adminData:action.payload.adminData};
        default:
            return {...state}

    }
}