import React from 'react';
import {reduxForm, Field} from "redux-form";
import {connect}from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import Transition from "../../Functions/Transistion";
import RenderInputs from "../../Functions/RenderInputs";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";


class LinkModal extends React.Component{


    onSubmit=(formValues)=>{
        this.props.onSave(formValues);
    };

    render(){
        const {classes,isVisible, onClose, heading, errMessage}=this.props;
        return(
            <div>

                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>{heading} </h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <div>
                            <h5 style={{color:'red'}}>{errMessage}</h5>
                            <Field id='name' name='name' placeholder='name' label='Name' component={RenderInputs}/>
                            <Field id='platform' name='platform' placeholder='android' label='Platform' component={RenderInputs}/>
                            <Field id='version' name='version' placeholder='1.01' label='Version' component={RenderInputs}/>
                            <Field id='link' name='link' placeholder='link' label='Link' component={RenderInputs}/>
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            //onClick={onSave}
                            type='submit'
                            color={'success'}>
                            Save
                        </Button>
                    </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }


}

const validate=(formValues)=>{
    const {name,platform,version,link}=formValues;
    const errors={};

    if(!name){
        errors.name="Please enter a valid name"
    }
    if(!platform){
        errors.platform='Please enter a valid platform'
    }
    if(!version){
        errors.version='Please enter a valid version'
    }

    if(!link){
        errors.link='Please enter a valid link'
    }

    return errors;
};

const form = reduxForm({
  form:'SoftwareLinks',
    validate,
    enableReinitialize:true
})(LinkModal);


const mapStateToProps=(state,ownProps)=>{

    let values = {};
    if(ownProps.data !== null){
        values=ownProps.data;
    }

  return {initialValues:values}
};

export default connect(mapStateToProps)(withStyles(modalStyle)(form));