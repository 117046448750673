import _ from 'lodash';

//const rex =/^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/;
const rex =/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
/*
    valueArray =[
        {
            name:'',
            type:'',
            label:'',
            placeholder:''
        }
    ]
 */
export const validateFormEntries=(valueArray,formValues)=>{
    const errors={};

    _.each(valueArray,({name,type})=>{
        if(type==='email'){
            if(!validateEmail(formValues[name])){
                errors[name]='Please enter a valid email address!'
            }
        }else if(!formValues[name]){
            errors[name]=`Please enter a ${name} value!`;
        }
    })

    return errors;
}


export const validateEmail=(email)=>{
    //email.trim();
    if(email){
        return !(!email && !email.length && !rex.test(email));
    }

    return true;



}

