import React from "react";
import {InputAdornment, Tooltip} from "@material-ui/core";
import {connect} from 'react-redux';
import {AddAlert, Search, PlayCircleFilled, PauseCircleFilled} from "@material-ui/icons";

//components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modals/Modal";
import {suspendEvent, getAdminData} from "../../Store/Actions/AdminActions";
import Snackbar from "../../components/Snackbar/Snackbar";
import Loading from "../../components/Loading";


class Events extends React.Component{

    state={
        loading:false,
        search:'',
        susModal:false,
        unSusModal:false,
        key:null,
        error:'',
        notif:false,
        notifMsg:'',
        mode:null
    };

    handleChange = name=>event=>{
        const value = event.target.value;
        this.setState({[name]:value})
    };

    renderEvents=()=>{
        const search = this.state.search.toLocaleLowerCase();
        if(this.props.events !== null){

            const tableData= Object.keys(this.props.events).reduce((a,v)=>{
                const {title,date, venue,status,scrutineer,organizer,organization,totalSections}=this.props.events[v];
                if(title.toLocaleLowerCase().includes(search)|| date.toLocaleLowerCase().includes(search)||
                    organizer.name.toLocaleLowerCase().includes(search)|| organization.toLocaleLowerCase().includes(search)||
                    status.toLocaleLowerCase().includes(search)|| venue.toLocaleLowerCase().includes(search)){

                    a.push([
                        title,
                        date,
                        organizer.name,
                        organization,
                        scrutineer.name,
                        totalSections,
                        status,
                        (<div>
                            {status !=='suspended'?
                                <Tooltip title='Suspend'>
                                    <Button size='sm' color='danger' justIcon round onClick={()=>this.setState({
                                        susModal:true,
                                        key:v,
                                        mode:'suspended'
                                    })}>
                                        <PauseCircleFilled/>
                                    </Button>
                                </Tooltip>:
                                <Tooltip title='Unsuspend'>
                                    <Button size='sm' color='success' justIcon round onClick={()=>this.setState({
                                        susModal:true,
                                        key:v,
                                        mode:'not-started'
                                    })}>
                                        <PlayCircleFilled/>
                                    </Button>
                                </Tooltip>
                            }
                        </div>)
                    ])

                }


                return a;
            },[]);

            const tableHEad=['Title','date','Organizer','Organization','Scrutineer','No Sections','Status','Actions'];

            return (
                <Table
                    tableHeaderColor={'info'}
                    tableHead={tableHEad}
                    tableData={tableData}
                />
            )


        }else{
            return (
                <div><h5>No events created yet.</h5></div>
            )
        }



    };

    handleSuspend=()=>{
        this.setState({loading:true});
        const {key,mode}=this.state;
        this.props.suspendEvent(key,mode,res=>{
            res.status !==1?
                this.setState({
                    error:res.msg,
                    loading:false
                }):
                this.props.getAdminData(()=>{
                    this.setState({
                        loading:false,
                        susModal:false,
                        notif:true,
                        mode:null,
                        notifMsg:mode==='suspended'?'Event suspended!':'Event unsuspended'
                    })
                })
        })
    };

    render(){
        const {susModal, notif, notifMsg, loading}=this.state;
        return (
            <div>
                <Loading
                   show={loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={notifMsg}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                  isVisible={susModal}
                  onClose={()=>this.setState({susModal: false,key:null})}
                  onSave={this.handleSuspend}
                  heading='Confirm Suspend'
                  saveButtonColor={'warning'}
                  saveButtonText={'Suspend'}
                  content={<div>Are you sure you want to suspend this event?</div>}
                />

                <Card>
                    <CardHeader color='info'>
                        <h4>Events</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={8}/>
                            <GridItem xs={12} sm={4}>
                                <CustomInput
                                    style={{justifyContent:'flex-start'}}
                                    value={this.state.search}
                                    labelText={'Search'}
                                    id={'search'}
                                    formControlProps={{
                                        fullWidth: false
                                    }}
                                    inputProps={{
                                        autoComplete:'off',
                                        value:this.state.search,
                                        onChange:(this.handleChange('search')),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                {this.renderEvents()}
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
const mapStateToProps =state=>{
    return {events:state.adminData.events}
};

export default connect(mapStateToProps, {suspendEvent,getAdminData})(Events);