import React from "react";

import {LinearProgress} from "@material-ui/core";

const LinearLoading=({show})=>{
    return show?
        <LinearProgress
            color={'primary'}
        />:null
}
export default LinearLoading;