import {auth,database,dbFire} from "../../firebase";
import {LOAD_ADMIN_DATA, LOAD_PDFMARKS, SET_PDF_VIEW} from "../types";

export const getAdminData =(callback)=>(dispatch)=>{


    database.ref().once('value').then(snap=>{

        const db = snap.val();
        const adminData = db.adminData;
        const events = Object.keys(db.events).reduce((a,v)=>{
            const event=db.events[v];
            let sectionsAmount =0;
            if(event.sections !== undefined){
                sectionsAmount=Object.keys(event.sections).length
            }

            a[v]={
                date:event.info.date,
                title:event.info.title,
                status:event.status,
                scrutineer:event.info.scrutineer,
                organization:event.info.organization,
                organizer:event.info.organizer,
                chairPerson:event.info.chairPerson,
                venue:event.info.venue,
                totalSections:sectionsAmount
            };

            return a;
        },{});

        const users = Object.keys(db.users).reduce((a,v)=>{
            a[v]=db.users[v];
            return a;
        },{});

        dispatch({type:LOAD_ADMIN_DATA,payload:{users,events,adminData}});
        callback({status:1});
    });
};


export const changeUserStatus=(uid,role,mode,callBack)=>(dispatch)=>{
    const ref = database.ref(`users/${role}/${uid}`);

    ref.update({
        status:mode
    },err=>{
        if(err){
            callBack({status:2,msg:err.message})
        }
    }).then(()=>{
        callBack({status:1})
    })


};

export const addLink=(formValues,callback)=>(dispatch)=>{

    const {name,version,link,platform}=formValues;
    const ref = database.ref(`adminData/softwareLink`);
    ref.push({
        name,
        version,
        link,
        platform
    },err=>{
        if(err){
            callback({status:2,msg:err.message})
        }
    }).then(()=>callback({status:1}))
};
export const updateLink=(formValues,id,callback)=>(dispatch)=>{
    const {name,version,link,platform}=formValues;
    const ref = database.ref(`adminData/softwareLink/${id}`);
    ref.update({
        name,
        version,
        link,
        platform
    },err=> {
        if (err) {
            callback({status: 2, msg: err.message})
        }
    }).then(()=>callback({status:1}))
};
export const deleteLink=(id,callback)=>(dispatch)=>{
    const ref = database.ref(`adminData/softwareLink/${id}`);
    ref.remove().then(()=>callback({status:1}))
};

// judge pads
export const addJudgePad=(formValues,callback)=>(dispatch)=>{

    const {email,password,deviceNo,adminPass}=formValues;

    auth.createUserWithEmailAndPassword(email,password).then(user=>{

        const ref = database.ref(`adminData/judgesPads/${user.user.uid}`);
        ref.update({
            email,
            password,
            deviceNo,
            adminPass,
            deviceId:'Unknown'
        },err=>err?callback({status:2,msg:err.message}):null).then(()=>callback({status:1}))


    }).catch(err=>
        callback({status:2,msg:err.message})
    )
};
export const updateJudgePad=(formValues,id,callback)=>(dispatch)=>{

    const {deviceNo,adminPass}=formValues;

    const ref = database.ref(`adminData/judgesPads/${id}`);
    ref.update({
        deviceNo,
        adminPass
    },err=>err?callback({status:2,msg:err.message}):null).then(()=>callback({status:1}))

};

// events
export const suspendEvent=(id,mode,callback)=>(dispatch)=>{
    const ref= database.ref(`events/${id}`);
    ref.update({
        status:mode,
    },err=>err?callback({status:2,msg:err.message}):null).then(()=>callback({status:1}))
};


// get base 64 pdf data
export const getPdfMarksData =(callback)=>(dispatch)=>{

    const ref = dbFire.collection('savedMarks');

    ref.get().then(snap=>{

        const data = snap.docs.reduce((a,v)=>{
            a.push({id:v.id,data:v.data()});
            return a;
        },[]);
        dispatch({type:LOAD_PDFMARKS,payload:data});
        callback({status:1,data:data});

    }).catch(err=>callback({status:2,msg:err}));

};

export const loadPdfMarksData=(data,callback)=>(dispatch)=>{

    dispatch({type:SET_PDF_VIEW,payload:data});
    callback()

};