import React from 'react';
import {connect}from 'react-redux';
import { Switch, Route } from "react-router-dom";
import history from "../history";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footer/FooterMain";
import Sidebar from "../components/Sidebar/Sidebar";

import image from "../assets/img/bg-1.jpg";
import logo from "../assets/img/icon_only-white.png";
import routes from "./Routes";
import dashboardStyle from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import SavedMarksView from "./SavedMarksView";
import {checkLogin} from "../Store/Actions/AuthActions";

const switchRoutes = (
    // make adjustments for items
    <Switch>
        {routes.map((prop, key) => {
            if(prop.items){
                return  prop.items.map(item=>{
                    return (
                        <Route
                            path={item.layout+item.path}
                            component={item.component}
                            key={key+item.name}
                            exact
                        />
                    )
                })
            }else{
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                        exact
                    />
                );
            }
        })
        }

        <Route
            path={'/home/savedMarks/view'}
            component={SavedMarksView}
            exact
        />
    </Switch>
);

class AdminHome extends React.Component{


    state={
        image: image,
        color: "blue",
        hasImage: true,
        fixedClasses: "dropdown show",
        mobileOpen: false
    };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };
    componentDidMount() {
        if(!this.props.Auth.isLoggedIn){
            history.push('/');
            //todo -write function to check if user is already logged in
        }
        if (navigator.platform.indexOf("Win") > -1) {
            const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }


    render(){
        const { classes, ...rest } = this.props;
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={routes}
                    logoText={"Dance Suite"}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    {...rest}
                />
                <div className={classes.mainPanel} ref="mainPanel">
                    <Navbar
                        eventTitle={'Admin Panel'}
                        routes={routes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}
const mapStateToProps=state=>{
    return {Auth:state.Auth}
};

export default connect(mapStateToProps, {checkLogin})(withStyles(dashboardStyle)(AdminHome));