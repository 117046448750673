import {AUTHLOGIN,AUTH_LOGOUT} from "../types";

const INITIAL_STATE={
    isLoggedIn:false,
}

export default (state=INITIAL_STATE,action)=>{
    switch (action.type) {
        case AUTHLOGIN:
            return {isLoggedIn: true}
        case AUTH_LOGOUT:
            return {isLoggedIn: false}
        default:
            return {...state};
    }

}