import React,{useEffect, useReducer} from "react";
import {Save} from "@material-ui/icons";
import {connect}from 'react-redux';
// components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import WYSIWYG from "../../components/WYSIWYG";
import CardFooter from "../../components/Card/CardFooter";
import Button from '../../components/CustomButtons/Button';
import {GetTerms, SaveTerms} from "../../Store/Actions/DisclaimerAction";
import LinearLoading from "../../components/Loading/LinearLoading";
import Loading from "../../components/Loading";
import Danger from "../../components/Typography/Danger";
import GridContainer from "../../components/Grid/GridContainer";
import FormatDate from "../../Functions/RenderDate";
import {initialState,reducer}from './Functions';


const Terms=({data, GetTerms, SaveTerms})=>{

    const [state,dispatch]= useReducer(reducer,initialState)
    const {content,loading,error,saving, updated}=state;

    useEffect(()=>{
        let subscribed=true;
        if(data){
            if(data.content){
                dispatch({type:'loadContent',payload:{content:data.content,updated:data.updated}})
            }else{
                dispatch({type:'dataLoaded'});
            }
        }

        if(!content && !data){
            GetTerms(res=>{
                if(subscribed){
                    res.status===1?
                        dispatch({type:'dataLoaded'}):
                        dispatch({type:'dataLoadedError',payload:res.msg})
                }
            });
        }

        return ()=>subscribed=false;
    },[data,content, GetTerms]);

    const OnSave=()=>{
        dispatch({type:'startSaving'});
        SaveTerms(content,res=>{
            res.status===1?
            dispatch({type:'endSaving'}):
            dispatch({type:'endSavingWithErr', payload:res.msg});
        })
    }

    return (
        <div>
            <Loading show={saving}/>
            <LinearLoading show={loading}/>
            <Card>
                <CardHeader color={'info'}>
                    <GridContainer justify={'space-between'} style={{paddingLeft:10,paddingRight:10}}>
                        <h4>Terms and Conditions</h4>
                        <h6>Last Updated: {updated?FormatDate(updated):'Not saved yet!'}</h6>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                    <Danger>{error}</Danger>
                    <WYSIWYG
                        content={content}
                        onChange={(c)=>dispatch({type:'updateContent',payload:c})}
                    />
                </CardBody>
                <CardFooter >
                    <Button onClick={OnSave} block color={'success'} size={'sm'} >
                        <Save/>
                        Save
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
}
const mapSateToProps=(state)=>{
    return {
        data:state.Disclaimers.terms
    }
}





export default connect(mapSateToProps,{GetTerms, SaveTerms})(Terms);