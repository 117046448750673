import {auth,database} from "../../firebase";
import {AUTH_LOGOUT, AUTHLOGIN} from "../types";

export const checkLogin=(callback)=>async dispatch=>{

    auth.onAuthStateChanged(user=>{
        if(user){
            const ref = database.ref('adminLogin/email');
            ref.once('value')
                .then(snap=>{
                    if(user.email === snap.val()){
                        dispatch({type:AUTHLOGIN});
                        callback({status:1});
                    }else{callback({status:2})}
                }).catch(err=>err?callback({status:2,msg:err.message}):null);
        }else{
            callback({status:2})
        }
    });
}

export const adminLogin=({email,password},callback)=>dispatch=>{

    auth.signInWithEmailAndPassword(email,password)
        .then(user=>{
            const ref = database.ref('adminLogin/email');
            ref.once('value').then(snap=>{
                const dbEmail = snap.val();
                if(dbEmail===email){
                    dispatch({type:AUTHLOGIN});
                    callback({status:1})
                }else{
                    callback({status:2,msg:'Incorrect email!'})
                }
            });
        })
        .catch((err)=>err?callback({status:2,msg:err.message}):null)
}

export const logOut=(callback)=>dispatch=>{
    auth.signOut()
        .then(()=>{
            dispatch({type:AUTH_LOGOUT})
            callback();
        }).catch(e=>console.log(e));

}