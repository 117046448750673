import CustomInput from "../components/CustomInput/CustomInput";
import React from "react";


const RenderInputs=({input,label,type,placeholder,name,meta, width})=>{
    let errorStatus = !!(meta.touched && meta.error);
    return (
        <CustomInput
            id={name}
            inputProps={{
                ...input,
                type,
                placeholder
            }}
            labelText={label}
            formControlProps={{
                fullWidth:width!==undefined?width:false
            }}
            error={errorStatus}
        />
    )
};

export default RenderInputs;