import {LOAD_PDFMARKS,SET_PDF_VIEW} from "../types";

const INITIAL_STATE={
  files:[],
  Loaded:false,
  SetFile:{}
};

export default (state=INITIAL_STATE,action)=>{

    switch (action.type) {
        case LOAD_PDFMARKS:
            return {...state,files:action.payload,Loaded: true};
        case SET_PDF_VIEW:
            return {...state,SetFile:action.payload};
        default:
            return {...state}
    }
}