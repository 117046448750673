
import {ListAlt, People, SupervisorAccount, TabletAndroid, Link, PlaylistAddCheck, Gavel} from '@material-ui/icons'
// components
import Events from "./Events/Events";
import Chairpersons from "./Users/Chairpersons";
import Scrutineers from "./Users/Scrutineers";
import AdminAdjPads from './adminJudgesPad';
import SoftwareLinks from './Software/SoftwareLinks';
import SavedMarks from "./SavedMarks";
import Terms from "./Discliamers/Terms";
import Privacy from "./Discliamers/Privacy";
const routes=[
    {
        path:'/events',
        name:'Events',
        Icon:ListAlt,
        layout:'/home',
        component:Events
    },
    {
        path:'/disclaimers',
        name:"Disclaimers",
        Icon:Gavel,
        layout:'/home',
        items:[
            {
                name:"Terms",
                path:'/terms',
                layout:'/home/disclaimers',
                component:Terms
            },
            {
                name:'Privacy',
                path:'/privacy',
                layout:'/home/disclaimers',
                component:Privacy
            }
        ],

    },
    {
        path:'/users',
        name:'Users',
        Icon:People,
        layout:'/home',
        items:[
            {
                name:'Scrutineers',
                path:'/scrutineers',
                layout:'/home/users',
                component:Scrutineers
            },
            {
                name:'Chairpersons',
                path:'/chairpersons',
                layout:'/home/users',
                component:Chairpersons
            }
        ]
    },
    {
        path:'/judgepads',
        name:'Judge Pads',
        Icon:TabletAndroid,
        layout:'/home',
        component:AdminAdjPads
    },
    {
        path:'/software',
        name:'Software',
        Icon:Link,
        layout:'/home',
        component:SoftwareLinks
    },
    {
        path:'/savedMarks',
        name:"Saved Marks",
        Icon:PlaylistAddCheck,
        layout:'/home',
        component:SavedMarks
    },


];
export default routes;