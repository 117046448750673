import React from "react";
import {Router,Route}from 'react-router-dom';

import './assets/css/material-dashboard-react.css';
//components
import Auth from "./Screens/Auth/Auth";
import history from "./history";
import AdminHome from "./Screens/AdminHome";
const App =()=>{
    return (
        <div>
            <Router history={history}>
                <Route exact path={'/'} component={Auth}/>
                <Route path={'/home'} component={AdminHome}/>
            </Router>
        </div>
    )
}
export default App;