import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import Transition from "../../Functions/Transistion";

//functions


class Modal extends React.Component{
    render(){
        const {classes}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                            <h5>{this.props.errMessage}</h5>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            {this.props.content}
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.props.onSave}
                                type='submit'
                                color={this.props.saveButtonColor ? this.props.saveButtonColor:'success' }>
                                {this.props.saveButtonText ? this.props.saveButtonText:'Save' }
                            </Button>
                        </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(modalStyle)(Modal)

