import {LOAD_PRIVACY, LOAD_TERMS} from "../types";

const INITIAL_STATE={
    terms:null,
    privacy:null
}

export default (state=INITIAL_STATE,action)=>{
    switch(action.type){
        case LOAD_PRIVACY:
            return {...state,privacy:action.payload};
        case LOAD_TERMS:
            return {...state,terms:action.payload};
        default:
            return {...state};
    }
}