import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import {Tooltip} from "@material-ui/core";
// @material-ui/icons
import {ExitToApp,Close} from "@material-ui/icons";
// core components
import Button from "../CustomButtons/Button.js";
import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import history from "../../history";
import {connect}from 'react-redux';
import {logOut} from "../../Store/Actions/AuthActions";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = function ({logOut}) {
  const classes = useStyles();
  const navigation=(path)=>{
        logOut(()=>history.push('/'))
    };

  return (
    <div>
      <Tooltip title={'View Site'} placement={'bottom'}>
          <a style={{color:"grey"}} href={'https://dance-suite.com'} target={'_blank'}>
            View Site
          </a>
      </Tooltip>

      <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="close"
          className={classes.buttonLink}
          onClick={()=>navigation('signOut')}
      >
        <Tooltip title='Sign Out' placement='bottom'>
          <ExitToApp className={classes.icons} />
        </Tooltip>
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Sign Out</p>
        </Hidden>
      </Button>

    </div>
  );
}

export default connect(null,{logOut})(AdminNavbarLinks);
