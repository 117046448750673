import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";

import Auth from './AuthReducer';
import AdminData from './AdminReducer';
import PdfMark from "./PdfMarksReducers";
import Disclaimers from './DiscliamersReducers';
const reducers = {
    form:formReducer,
    Auth,
    adminData:AdminData,
    pdfMarks:PdfMark,
    Disclaimers
}

export default combineReducers(reducers);