import React,{useReducer, useEffect} from "react";
import {connect}from 'react-redux';
import {Save} from "@material-ui/icons";
//components
import Loading from "../../components/Loading";
import LinearLoading from "../../components/Loading/LinearLoading";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer";
import FormatDate from "../../Functions/RenderDate";
import CardBody from "../../components/Card/CardBody";
import WYSIWYG from "../../components/WYSIWYG";
import CardFooter from "../../components/Card/CardFooter";
import Button from '../../components/CustomButtons/Button';
import Danger from "../../components/Typography/Danger";
import {GetPrivacy, SavePrivacy} from "../../Store/Actions/DisclaimerAction";
import {reducer,initialState} from "./Functions";


const Privacy=({data,GetPrivacy,SavePrivacy})=>{

    const [state,dispatch]=useReducer(reducer,initialState)

    const {loading,error,saving,updated,content}=state;

    useEffect(()=>{
        let subscribed=true;
        if(subscribed){
            if(data){
                if(data.content){
                    dispatch({type:'loadContent',payload:{content:data.content,updated:data.updated}})
                }else{
                    dispatch({type:'dataLoaded'});
                }
            }

            if(!content && !data && subscribed){
                console.log('getPrivacy called');
                GetPrivacy(res=>{
                    if(subscribed){
                        res.status===1?
                            dispatch({type:'dataLoaded'}):
                            dispatch({type:'dataLoadedError',payload:res.msg})
                    }

                });
            }
        }


        return ()=>subscribed=false;

    },[data, content, GetPrivacy]);



    const handleSave=()=>{
        dispatch({type:'startSaving'});
        SavePrivacy(content,res=>{
            res.status===1?
                dispatch({type:'endSaving'}):
                dispatch({type:'endSavingWithErr', payload:res.msg});
        })
    }

    return (
        <div>
            <Loading show={saving}/>
            <LinearLoading show={loading}/>
            <Card>
                <CardHeader color={'info'}>
                    <GridContainer style={{paddingLeft:10,paddingRight:10}} justify={'space-between'}>
                        <h4>Privacy Statement</h4>
                        <h6>Last Updated: {updated?FormatDate(updated):'Not Saved Yet'}</h6>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                    <Danger>{error}</Danger>
                    <WYSIWYG
                        content={content}
                        onChange={c=>dispatch({type:'updateContent',payload:c})}
                    />
                </CardBody>
                <CardFooter>
                    <Button onClick={handleSave} color={'success'} round block size={'sm'}>
                        <Save/>
                        Save
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
}

const mapStateToProps=(state)=>{
   return {data:state.Disclaimers.privacy}
}


export default connect(mapStateToProps,{GetPrivacy, SavePrivacy})(Privacy);