import React from "react";
import Loading from "../components/Loading";
import {connect}from 'react-redux';
import {getPdfMarksData, loadPdfMarksData} from "../Store/Actions/AdminActions";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import {Tooltip} from "@material-ui/core";
import Button from "../components/CustomButtons/Button";
import {CloudDownload} from "@material-ui/icons";
import Table from "../components/Table/Table";
import history from "../history";


class SavedMarks extends React.Component{

    state={
        loading:true,
        error:false,
        errorMsg:'',
        data:[]
    };

    componentDidMount=()=>{

        this.props.getPdfMarksData(res=>{

            res.status!==1?
                this.setState({loading:false,error:true,errorMsg:res.msg}):
                this.setState({loading:false,error:false,errorMsg:''})
        })
    };

    handleOpen=(data)=>{
            this.props.loadPdfMarksData(data,res=>{
                history.push('/home/savedMarks/view')
            })
    };


    renderData=()=>{
        const {error} = this.state;
        const {files,loaded}=this.props;
        const data=files;
          if(data.length>0 && !error && loaded){
              const tableData = data.reduce((a,v,i)=>{

                  const {deviceNumber,timeStamp,deviceId,base64Code}=data[i].data;

                  a.push([
                      deviceNumber,
                      deviceId,
                      new Date(timeStamp).toDateString() +' '+new Date(timeStamp).toLocaleTimeString(),
                      <div>
                          <Tooltip title={'Download PDf'}>
                              <a href={`data:application/pdf;base64,${base64Code}`} download={`${timeStamp}.pdf`}>
                                  <Button color='info' size='sm' round justIcon>
                                      <CloudDownload/>
                                  </Button>
                              </a>
                          </Tooltip>
                      </div>

                  ]);

                  return a;
              },[]);
              const tableHEad =['Device No','DeviceId','Time','Action'];
              return (
                  <Table
                      tableHead={tableHEad}
                      tableData={tableData}
                      tableHeaderColor={'info'}
                  />
              )



      }else{
          return (
              <div>
                  No data to display.
              </div>
          )
      }
    };



    render(){
        return (
            <div>
                <Loading
                    show={this.state.loading}
                />
                <Card>
                    <CardHeader color={'info'}>
                        <h4>Saved Tablet Data</h4>
                    </CardHeader>
                    <CardBody>
                        {this.renderData()}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
  return {
      files:state.pdfMarks.files,
      loaded:state.pdfMarks.Loaded
  }
};


export default connect(mapStateToProps,{getPdfMarksData,loadPdfMarksData})(SavedMarks);