const reducer=(state,action)=>{
    switch(action.type){
        case 'updateContent':
            return {...state,content:action.payload};
        case 'loadContent':
            return {...state,content: action.payload.content, loading:false,updated:action.payload.updated}
        case 'dataLoaded':
            return {...state,loading:false,error:null};
        case 'dataLoadedError':
            return {...state,loading:false,error:action.payload};
        case 'startSaving':
            return {...state,saving:true};
        case'endSaving':
            return {...state,saving:false};
        case 'endSavingWithErr':
            return {...state,saving:false,error:action.payload}
        default:
            return {...state};
    }
}

const initialState={
    content:null,
    loading:true,
    error:null,
    saving:false,
    updated:null
}

export {initialState,reducer}