import React from 'react';
import {connect}from 'react-redux';
import {Close, Edit, AddBox, AddAlert} from '@material-ui/icons'
import {Tooltip} from "@material-ui/core";


//components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import Table from "../../components/Table/Table";
import LinkModal from '../../components/Modals/linkModal';
import Loading from "../../components/Loading";
import {addLink,deleteLink,updateLink, getAdminData} from "../../Store/Actions/AdminActions";
import Modal from "../../components/Modals/Modal";
import Snackbar from "../../components/Snackbar/Snackbar";

class SoftwareLinks extends React.Component{

    state={
        modalHeading:'',
        linkModal:false,
        loading:false,
        key:null,
        linkData:null,
        errMsg:'',
        deleteModal:false,
        notif:false,
        notifMsg:''
    };


    renderLinks=()=>{

        const {links}=this.props;
        if(links !== null && links !== undefined){
            if(Object.keys(links).length>0){

                const tableData= Object.keys(links).reduce((a,v)=>{
                    const {link,name,platform,version} = links[v];
                    a.push([
                       name,
                       platform,
                       version,
                       <a href={link} target="_blank" rel="noopener noreferrer"  >Download</a>,
                       (<div>
                           <Tooltip title='Delete Link'>
                               <Button color='danger' round justIcon size='sm' onClick={()=>this.setState({key:v, deleteModal:true})}>
                                   <Close/>
                               </Button>
                           </Tooltip>
                           <Tooltip title='Edit Link'>
                               <Button color='info' round justIcon size='sm' onClick={()=>this.setState({key:v,linkData:links[v],linkModal:true, modalHeading:'Edit Link'})}>
                                   <Edit/>
                               </Button>
                           </Tooltip>
                       </div>)
                    ]);

                return a
                },[]);

                const tableHEad = ['Name','Platform','Version','Link','Actions'];

                return (
                    <Table
                        tableHeaderColor={'info'}
                        tableHead={tableHEad}
                        tableData={tableData}
                    />
                )



            }else{
                return <div>No Links Added yet</div>
            }
        }else{
            return <div>No Links Added yet</div>
        }

    };

    onConfirmDelete=()=>{
        this.setState({loading:true});
        this.props.deleteLink(this.state.key,()=>{
            this.props.getAdminData(()=>
                this.setState({
                    loading:false,
                    key:null,
                    deleteModal:false,
                    notif:true,
                    notifMsg:'Link Deleted Successfully'
                })
            )

        })
    };

    onSaveLink=(formValues)=>{
        const {key}=this.state;
        this.setState({loading:true});
        if(key){
            // update existing link
            this.props.updateLink(formValues,key,res=>{
                res.status !== 1?this.setState({loading:false,errMsg:res.msg}):
                    this.props.getAdminData(()=>this.setState({
                        loading:false,
                        linkModal:false,
                        key:null,
                        linkData:null,
                        errMsg:'',
                        modalHeading:'',
                        notif:true,
                        notifMsg:'Link Updated Successfully'
                    }))
            })
        }else{
            // add new link
            this.props.addLink(formValues,res=>{
             res.status !== 1?this.setState({loading:false,errMsg:res.msg}):
                this.props.getAdminData(()=>this.setState({
                    loading:false,
                    linkModal:false,
                    key:null,
                    linkData:null,
                    errMsg:'',
                    modalHeading:'',
                    notif:true,
                    notifMsg:'Link Added Successfully'
                }))
            })
        }
    };

    render(){
        const {linkModal, loading,linkData, modalHeading, errMsg, deleteModal, notif,notifMsg}=this.state;
        return (
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={notifMsg}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                    heading={'Confirm Delete'}
                    isVisible={deleteModal}
                    onClose={()=>this.setState({deleteModal: false})}
                    content={<div>Are you sure you want to delete this link?</div>}
                    onSave={this.onConfirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                />
                <Loading
                    show={loading}
                />
                <LinkModal
                    data={linkData}
                    isVisible={linkModal}
                    heading={modalHeading}
                    errMessage={errMsg}
                    onClose={()=>this.setState({key:null,linkData:null,linkModal:false,errMsg:''})}
                    onSave={this.onSaveLink}
                />
               <Card>
                   <CardHeader color={"info"}>
                       <h4>Software Links</h4>
                       <Button color={'rose'} size={'sm'} round onClick={()=>this.setState({linkModal:true, modalHeading: 'Add link'})}>
                           <AddBox/>
                           Add link
                       </Button>
                   </CardHeader>
                   <CardBody>
                       {this.renderLinks()}
                   </CardBody>
               </Card>
            </div>
        )
    }
}
const mapStateToProps=state=>{

    if(state.adminData.adminData !== null){
        return {links:state.adminData.adminData.softwareLink}
    }else{
        return {links:null}
    }

};
export default connect(mapStateToProps,{addLink,updateLink,deleteLink,getAdminData})(SoftwareLinks)