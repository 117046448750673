import React from "react";
import {connect}from 'react-redux';
import {AddAlert, Pause, PlayArrow} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";

//components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Snackbar from "../../components/Snackbar/Snackbar";
import Loading from "../../components/Loading";
import Modal from "../../components/Modals/Modal";
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import Button from "../../components/CustomButtons/Button";
import Table from "../../components/Table/Table";
import {changeUserStatus, getAdminData} from "../../Store/Actions/AdminActions";

class Chairpersons extends React.Component{
    state={
        loading:false,
        notif:false,
        notifMsg:'',
        uid:null,
        modal:false,
        modalHeading:'',
        userName:'',
        mode:null,
        errorMsg:''
    };
    renderChairs=()=>{
      if(this.props.chairs !== null && this.props.chairs !== undefined){
          const chairpersons = this.props.chairs;
          const tableData = Object.keys(chairpersons).reduce((a,v)=>{

              const {firstName,lastName,email,country, lastLogin, status}=chairpersons[v];
              a.push([
                  firstName +' '+lastName,
                  email,
                  country,
                  new Date(lastLogin).toString(),
                  status,
                  (<div>
                      {status ==='disabled'?
                          <Tooltip title={'Enable user'}>
                              <Button onClick={()=>this.setState({
                                  uid:v,
                                  userName:firstName+' '+lastName,
                                  modal:true,
                                  modalHeading:'Enable user',
                                  mode:'enabled'
                              })} color='success' size='sm' round justIcon><PlayArrow/></Button>
                          </Tooltip>
                          :
                          <Tooltip title='disable user'>
                              <Button onClick={()=>this.setState({
                                  uid:v,
                                  userName:firstName+' '+lastName,
                                  modal:true,
                                  modalHeading:'Disable user',
                                  mode:'disabled'
                              })} color='danger' round justIcon size='sm'><Pause/></Button>
                          </Tooltip>

                      }
                  </div>)
              ]);

              return a;
          },[]);

          const tableHead=['Name','Email','Country','Last Login', 'Status', 'Actions'];
          return (
              <Table
                  tableHead={tableHead}
                  tableData={tableData}
                  tableHeaderColor={'warning'}
              />
          )


      }  else{
          return (<div><h5>No Chairpersons Yet</h5></div>)
      }
    };

    saveStatus=()=>{
        this.setState({loading:true});
        const {uid,mode}=this.state;
        this.props.changeUserStatus(uid,'chairperson',mode,(res)=>{
            if(res.status!==1){
                this.setState({loading:false, errorMsg:res.msg})
            }else{
                    this.props.getAdminData(()=>
                        this.setState({
                            loading:false,
                            modal:false,
                            notif:true,
                            notifMsg:'Status Updated',
                            modalHeading:'',
                            uid:null,
                            mode:null,
                            errorMsg:'',
                            userName:''
                        }))


            }

        })
    };


    render(){
        const {modal, modalHeading,userName, mode, loading}=this.state;
        return (
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={loading}
                />
                <Modal
                    isVisible={modal}
                    heading={modalHeading}
                    content={
                        mode === 'enabled'?<Success>Are you sure you want to enable {userName}</Success>:
                            <Danger>Are you sure you want to disable {userName}</Danger>
                    }
                    onClose={()=>this.setState({modal: false,modalHeading: '', uid:null,userName: '', mode:null,})}
                    onSave={this.saveStatus}
                    saveButtonText={mode === 'enabled'?'Enable':'Disable'}
                    saveButtonColor={mode ==='enabled'?'success':'danger'}
                />
                <Card>
                    <CardHeader color='warning'>
                        <h4>Chairpersons</h4>
                    </CardHeader>
                    <CardBody>
                        {this.renderChairs()}
                    </CardBody>
                </Card>
            </div>
        )
    }
}

const mapStateToProps=state=>{
  if(state.adminData.users === null){
      return {chairs:state.adminData.users}
  }else{
      return {chairs:state.adminData.users.chairperson}
  }
};

export default connect(mapStateToProps,{changeUserStatus, getAdminData})(Chairpersons);