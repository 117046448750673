import {database, dbFire} from "../../firebase";
import {LOAD_PRIVACY, LOAD_TERMS} from "../types";

export const GetTerms=(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('terms');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content,updated}=doc.data();
                dispatch({type:LOAD_TERMS,payload:{content,updated, loaded:true}});
            }else{
                dispatch({type:LOAD_TERMS,payload:{loaded:true}});
            }
            callback({status:1})
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
}

export const SaveTerms=(content,callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('terms');
    const data = {
        content,
        updated:Date.now(),
    }
    ref.set(data).then(()=>{
        data['loaded']=true;
        dispatch({type:LOAD_TERMS,payload:data});
        callback({status:1})
    })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
}

export const GetPrivacy=(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('privacy');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content,updated}=doc.data();
                dispatch({type:LOAD_PRIVACY,payload:{content,updated,loaded:true}})
            }
            callback({status:1})
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
};

export const SavePrivacy=(content,callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('privacy');
    const data={
        content,
        updated:Date.now()
    }
    ref.set(data).then(()=>{
        data['loaded']=true;
        dispatch({type:LOAD_PRIVACY,payload:data});
        callback({status:1})
    }).catch(err=>err?callback({status:2,msg:err.message}):null)
}
