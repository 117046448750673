import React from "react";
import {connect} from 'react-redux';
import {Tooltip} from "@material-ui/core";
import {AddAlert, Pause, PlayArrow} from '@material-ui/icons'


//  components
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Button from "../../components/CustomButtons/Button";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modals/Modal";
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import Loading from "../../components/Loading";
import {changeUserStatus, getAdminData} from "../../Store/Actions/AdminActions";
import Snackbar from "../../components/Snackbar/Snackbar";

class Scrutineers extends React.Component{

    state={
        loading:false,
        notif:false,
        notifMsg:'',
        uid:null,
        modal:false,
        modalHeading:'',
        userName:'',
        mode:null,
        errorMsg:''
    };

    renderScrutineer=()=>{
        if(this.props.scrutineers !== null && this.props.scrutineers !== undefined){

            const scrutineers =this.props.scrutineers;
            const tableData = Object.keys(scrutineers).reduce((a,v)=>{
                    const {firstName,lastName,email,country, lastLogin, status}=scrutineers[v];
                a.push([
                    firstName +' '+lastName,
                    email,
                    country,
                    new Date(lastLogin).toString(),
                    status,
                    (<div>
                        {status ==='disabled'?
                            <Tooltip title={'Enable user'}>
                                <Button onClick={()=>this.setState({
                                    uid:v,
                                    userName:firstName+' '+lastName,
                                    modal:true,
                                    modalHeading:'Enable user',
                                    mode:'enabled'
                                })} color='success' size='sm' round justIcon><PlayArrow/></Button>
                            </Tooltip>
                        :
                            <Tooltip title='disable user'>
                                <Button onClick={()=>this.setState({
                                    uid:v,
                                    userName:firstName+' '+lastName,
                                    modal:true,
                                    modalHeading:'Disable user',
                                    mode:'disabled'
                                })} color='danger' round justIcon size='sm'><Pause/></Button>
                            </Tooltip>

                        }
                    </div>)
                ]);

               return a;
            },[]);

            const tableHead=['Name','Email','Country','Last Login', 'Status', 'Actions'];
            return (
                <Table
                    tableHead={tableHead}
                    tableData={tableData}
                    tableHeaderColor={'info'}
                />
            )


        }else{
            return (<div><h5>No Scrutineers yet!</h5></div>)
        }
    };

    saveStatus=()=>{
        this.setState({loading:true});
        const {uid,mode}=this.state;
        this.props.changeUserStatus(uid,'scrutineer',mode,(res)=>{
            if(res.status!==1){
                this.setState({loading:false, errorMsg:res.msg})
            }else{
                this.props.getAdminData(()=>
                    this.setState({
                        loading:false,
                        modal:false,
                        notif:true,
                        notifMsg:'Status Updated',
                        modalHeading:'',
                        uid:null,
                        mode:null,
                        errorMsg:'',
                        userName:''
                    }))


            }
        })
    };

    render(){
        const {modal, modalHeading,userName, mode, loading, errorMsg}=this.state;
        return (
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={loading}
                />
                <Modal
                    errMessage={errorMsg}
                    isVisible={modal}
                    heading={modalHeading}
                    content={
                        mode === 'enabled'?<Success>Are you sure you want to enable {userName}</Success>:
                            <Danger>Are you sure you want to disable {userName}</Danger>
                    }
                    onClose={()=>this.setState({errorMsg:'',modal: false,modalHeading: '', uid:null,userName: '', mode:null,})}
                    onSave={this.saveStatus}
                    saveButtonText={mode === 'enabled'?'Enable':'Disable'}
                    saveButtonColor={mode ==='enabled'?'success':'danger'}
                />
               <Card>
                   <CardHeader color={'primary'}>
                       <h4>Scrutineers</h4>
                   </CardHeader>
                   <CardBody>
                       {this.renderScrutineer()}
                   </CardBody>
               </Card>
            </div>
        )
    }
}

const mapStateToProps=state=>{
    if(state.adminData.users === null){
        return {scrutineers: state.adminData}
    }else{
        return {scrutineers:state.adminData.users.scrutineer}
    }



};


export default connect(mapStateToProps,{changeUserStatus, getAdminData})(Scrutineers);