import React from 'react';
import {reduxForm, Field} from "redux-form";
import {connect}from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import Transition from "../../Functions/Transistion";
import RenderInputs from "../../Functions/RenderInputs";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";


class PadModal extends React.Component{

    onSubmit=(formValues)=>{
        this.props.onSave(formValues);
    };

    render(){
        const {classes,isVisible, onClose, heading, errMessage, editMode}=this.props;
        return(
            <div>

                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)} >
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <div>
                                <h5 style={{color:'red'}}>{errMessage}</h5>
                                {!editMode?
                                    <div>
                                    <Field id='email' name='email' placeholder='judge@mail.com' label='Email' component={RenderInputs}/>
                                    <Field id='password' name='password' placeholder='password' label='Password' component={RenderInputs}/>
                                    </div>:null
                                }

                                <Field id='deviceNo' name='deviceNo' placeholder='1.01' label='Device No' component={RenderInputs}/>
                                <Field id='adminPass' name='adminPass' placeholder='adminPass' label='Admin Password' component={RenderInputs}/>
                            </div>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                //onClick={onSave}
                                type='submit'
                                color={'success'}>
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }

}
const validate=(formValues)=>{
  const errors={};
  const {email,password,deviceNo,adminPass}=formValues;

  if(!email){
      errors.email='Please enter a valid email!';
  }
  if(!password){
      errors.password='Please enter a valid password';
  }
  if(!deviceNo){
      errors.deviceNo='Please enter a device no';
  }

  if(!adminPass){
      errors.adminPass='Please enter an admin password';
  }

  return errors;
};

const form =reduxForm({
    form:'Pads',
    validate,
    enableReinitialize:true
})(PadModal);

const mapStateToProps=(state,ownProps)=>{
    let values = {};
    if(ownProps.data !== null){
        values=ownProps.data;
    }

    return {initialValues:values}
};


export default connect(mapStateToProps)(withStyles(modalStyle)(form))